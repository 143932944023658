@charset "UTF-8";
.bs-w-affiliate-program article:nth-child(even) {
  background-color: #F6F8FB;
  position: relative;
}
.bs-w-affiliate-program article p {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  margin-block-end: 1.875rem;
}
@media screen and (min-width: 64rem) {
  .bs-w-affiliate-program article p {
    font-size: 1.375rem;
    line-height: 1.3636363636;
    margin-block-end: 3.75rem;
  }
}

.bs-w-a-p-intro h1 {
  margin-block-end: 1.25rem;
}
@media screen and (min-width: 64rem) {
  .bs-w-a-p-intro h1 {
    margin-block-end: 1.875rem;
    margin-inline: auto;
    max-inline-size: 39.5rem;
  }
}
.bs-w-a-p-intro .bs-w-article-content {
  padding-block-start: 2.5rem;
}
@media screen and (min-width: 64rem) {
  .bs-w-a-p-intro .bs-w-article-content {
    padding-block-start: 3.6875rem;
    padding-block-end: 8.75rem;
  }
}
@media screen and (min-width: 64rem) {
  .bs-w-a-p-intro {
    text-align: center;
  }
  .bs-w-a-p-intro .bs-w-button {
    cursor: pointer;
    inline-size: 15.375rem;
    margin-inline: auto;
  }
}

article.bs-w-a-p-how-it-works h2 {
  margin-block-end: 1.25rem;
}
@media screen and (min-width: 64rem) {
  article.bs-w-a-p-how-it-works h2:first-of-type {
    margin-block-end: 2.5rem;
  }
}
article.bs-w-a-p-how-it-works ul {
  margin: 0;
  margin-block-end: 3.75rem;
  list-style: none;
  padding: 0;
}
article.bs-w-a-p-how-it-works ul li + li {
  margin-block-start: 2.5rem;
}
article.bs-w-a-p-how-it-works ul li figure {
  column-gap: 1.25rem;
  margin: 0;
}
article.bs-w-a-p-how-it-works ul li figure svg {
  flex-shrink: 0;
}
article.bs-w-a-p-how-it-works ul li figure h3 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-block-end: 0.625rem;
}
article.bs-w-a-p-how-it-works ul li figure a {
  color: var(--bs-g);
}
article.bs-w-a-p-how-it-works ul.bs-w-a-p-rewards {
  row-gap: 0.25rem;
}
article.bs-w-a-p-how-it-works ul.bs-w-a-p-rewards li {
  background-color: var(--bs-w);
  border: 1px solid var(--bs-o);
  border-radius: 0.375rem;
  box-shadow: 4px 4px 6px 0 rgba(0, 0, 0, 0.02);
  padding-block: 0.9375rem;
  padding-inline: 1.25rem;
}
article.bs-w-a-p-how-it-works ul.bs-w-a-p-rewards li + li {
  margin: 0;
}
article.bs-w-a-p-how-it-works ul.bs-w-a-p-rewards li strong {
  color: var(--bs-b);
}
article.bs-w-a-p-how-it-works ul.bs-w-a-p-rewards li strong:last-of-type {
  column-gap: 0.625rem;
  inline-size: 5.25rem;
}
article.bs-w-a-p-how-it-works ul.bs-w-a-p-rewards li strong:last-of-type span:last-child {
  font-size: 1.5rem;
}
article.bs-w-a-p-how-it-works ul.bs-w-a-p-rewards li:last-child strong:first-of-type {
  max-inline-size: 7.6875rem;
}
@media screen and (min-width: 64rem) {
  article.bs-w-a-p-how-it-works .bs-w-article-content {
    display: grid;
    column-gap: 12.5rem;
    grid-template-columns: 1fr max-content;
  }
  article.bs-w-a-p-how-it-works .bs-w-article-content > :nth-child(1),
article.bs-w-a-p-how-it-works .bs-w-article-content > :nth-child(2) {
    grid-column: 1;
  }
  article.bs-w-a-p-how-it-works .bs-w-article-content > :nth-child(3),
article.bs-w-a-p-how-it-works .bs-w-article-content > :nth-child(4),
article.bs-w-a-p-how-it-works .bs-w-article-content > :nth-child(5) {
    grid-column: 2;
  }
  article.bs-w-a-p-how-it-works .bs-w-article-content > :nth-child(6) {
    grid-column: span 2;
    inline-size: 26.5rem;
    margin-inline: auto;
  }
  article.bs-w-a-p-how-it-works .bs-w-article-content > :nth-child(1),
article.bs-w-a-p-how-it-works .bs-w-article-content > :nth-child(3) {
    grid-row: 1;
  }
  article.bs-w-a-p-how-it-works .bs-w-article-content > :nth-child(4) {
    grid-row: 2;
  }
  article.bs-w-a-p-how-it-works .bs-w-article-content > :nth-child(2),
article.bs-w-a-p-how-it-works .bs-w-article-content > :nth-child(5) {
    grid-row: 3;
  }
  article.bs-w-a-p-how-it-works p {
    margin-block-end: 2.5rem;
  }
}

article.bs-w-a-p-info h2 {
  margin-block-end: 2.5rem;
}
@media screen and (min-width: 64rem) {
  article.bs-w-a-p-info h2 {
    margin-block-end: 3.75rem;
    text-align: center;
  }
}
article.bs-w-a-p-info h3 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-block-end: 1.25rem;
}
article.bs-w-a-p-info ul {
  list-style: none;
  margin-block: 1.25rem;
  padding-inline-start: 1.875rem;
}
article.bs-w-a-p-info ul li {
  font-size: 1.125rem;
  position: relative;
}
article.bs-w-a-p-info ul li + li {
  margin-block-start: 1.875rem;
}
article.bs-w-a-p-info ul li:before {
  content: "•";
  position: absolute;
  inset-inline-start: -1.25rem;
}
article.bs-w-a-p-info .bs-w-article-content > a {
  color: var(--bs-g);
  display: inline-block;
  font-size: 0.75rem;
  margin-block-end: 2.5rem;
}
@media screen and (min-width: 64rem) {
  article.bs-w-a-p-info .bs-w-article-content > a {
    margin-block-end: 5rem;
  }
}
article.bs-w-a-p-info p a {
  color: var(--bs-g);
}
@media screen and (min-width: 64rem) {
  article.bs-w-a-p-info {
    margin-inline: auto;
    max-inline-size: 57.5rem;
  }
}

@media screen and (min-width: 64rem) {
  article.bs-w-a-p-join {
    text-align: center;
  }
  article.bs-w-a-p-join .bs-w-article-content {
    margin-inline: auto;
    max-inline-size: 46.25rem;
  }
  article.bs-w-a-p-join .bs-w-button {
    inline-size: 26.5rem;
    margin-inline: auto;
  }
}